.bubble-background {
  height: 100vh;
  width: 100vw;
}

@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #dc3545;
  overflow: hidden;
  z-index: -1;
}

.background span {
  width: 11vmin;
  height: 11vmin;
  border-radius: 11vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  color: #ec6a77;
}


.background span:nth-child(0) {
  top: 11%;
  left: 22%;
  animation-duration: 42s;
  animation-delay: -33s;
  transform-origin: 8vw 9vh;
  box-shadow: -22vmin 0 2.9295872622393673vmin currentColor;
}
.background span:nth-child(1) {
  top: 11%;
  left: 26%;
  animation-duration: 42s;
  animation-delay: -1s;
  transform-origin: 17vw 2vh;
  box-shadow: 22vmin 0 3.697358327492984vmin currentColor;
}
.background span:nth-child(2) {
  top: 60%;
  left: 90%;
  animation-duration: 29s;
  animation-delay: -12s;
  transform-origin: 23vw 14vh;
  box-shadow: -22vmin 0 3.529007547803604vmin currentColor;
}
.background span:nth-child(3) {
  top: 64%;
  left: 79%;
  animation-duration: 21s;
  animation-delay: -29s;
  transform-origin: -19vw -1vh;
  box-shadow: -22vmin 0 3.027121323503139vmin currentColor;
}
.background span:nth-child(4) {
  top: 4%;
  left: 80%;
  animation-duration: 27s;
  animation-delay: -50s;
  transform-origin: -18vw -5vh;
  box-shadow: 22vmin 0 3.6470468794862043vmin currentColor;
}
.background span:nth-child(5) {
  top: 5%;
  left: 64%;
  animation-duration: 19s;
  animation-delay: -34s;
  transform-origin: -15vw 15vh;
  box-shadow: 22vmin 0 3.2742970574285124vmin currentColor;
}
.background span:nth-child(6) {
  top: 47%;
  left: 29%;
  animation-duration: 50s;
  animation-delay: -30s;
  transform-origin: -12vw -2vh;
  box-shadow: -22vmin 0 3.326499205480453vmin currentColor;
}
.background span:nth-child(7) {
  top: 45%;
  left: 91%;
  animation-duration: 26s;
  animation-delay: -50s;
  transform-origin: -21vw -13vh;
  box-shadow: 22vmin 0 2.911941650276666vmin currentColor;
}
.background span:nth-child(8) {
  top: 23%;
  left: 93%;
  animation-duration: 35s;
  animation-delay: -18s;
  transform-origin: 5vw 22vh;
  box-shadow: 22vmin 0 2.8926860537268313vmin currentColor;
}
.background span:nth-child(9) {
  top: 69%;
  left: 80%;
  animation-duration: 51s;
  animation-delay: -20s;
  transform-origin: 10vw -9vh;
  box-shadow: 22vmin 0 2.7993062646942404vmin currentColor;
}
