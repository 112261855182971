.modale {
  width: 700px;
  max-width: 90vw;
  max-height: calc(100vh - 2rem);
  overflow-y: scroll;
  animation: popup 1s ease;
}

.modale * {
  opacity: 0;
  animation: fadein 0.3s ease-in-out forwards;
  animation-delay: 1s;
}

@keyframes popup {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}