button.selection {
  box-shadow: none !important;
  transition: 0.5s ease;
  border: 1px solid #dc3545;
  color: #dc3545;
  background-color: white;
}

button.selection.selected {
  background-color: #dc3545;
  color: white;
}

button.selection:hover {
  color: #dc3545;
}

button.selection.selected:hover {
  color: white;
}

button.selection:hover {
  transform: scale(0.95);
}

button.selection:active {
  transform: scale(0.8);
}

img.illustration {
  max-width: 80%;
  margin: 20px auto 0px;
  display: block;
}

.info-text {
  color: #888;
}